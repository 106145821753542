import { VERTICALS, VERTICALS_DISPLAY_NAMES } from '@/constants';
import {
  RebrandingTutoringIcon,
  RebrandingSeniorCareIcon,
  RebrandingPetCareIcon,
  RebrandingHousekeepingIcon,
  RebrandingChildCareIcon,
  RebrandingAdultCareIcon,
} from '@/components/svg/Rebranding';

type VerticalsDisplayInfoProp = {
  iconSize?: number;
  iconColor?: string;
  isDaycareRemoved?: boolean;
  adultCareTileTest?: number;
  seekerAdultCareExperience?: number;
};

const verticalsDisplayInfo = (prop?: VerticalsDisplayInfoProp) => {
  const childcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.CHILD_CARE],
    vertical: VERTICALS.CHILD_CARE,
    icon: <RebrandingChildCareIcon />,
  };

  const seniorcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.SENIOR_CARE],
    vertical: VERTICALS.SENIOR_CARE,
    icon: <RebrandingSeniorCareIcon />,
  };

  const tutoring = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.TUTORING],
    vertical: VERTICALS.TUTORING,
    icon: <RebrandingTutoringIcon />,
  };

  const housekeeping = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.HOUSEKEEPING],
    vertical: VERTICALS.HOUSEKEEPING,
    icon: <RebrandingHousekeepingIcon />,
  };

  const daycare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.DAY_CARE],
    vertical: VERTICALS.DAY_CARE,
    icon: undefined,
  };

  const petcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.PET_CARE],
    vertical: VERTICALS.PET_CARE,
    icon: <RebrandingPetCareIcon />,
  };

  const adultcare = {
    displayName: VERTICALS_DISPLAY_NAMES[VERTICALS.ADULT_CARE],
    vertical: VERTICALS.ADULT_CARE,
    icon: <RebrandingAdultCareIcon />,
  };

  if (
    (prop?.adultCareTileTest && [2, 3].includes(prop.adultCareTileTest)) ||
    (prop?.seekerAdultCareExperience && [2, 3].includes(prop.seekerAdultCareExperience))
  ) {
    return prop.adultCareTileTest === 2
      ? [childcare, adultcare, seniorcare, housekeeping, petcare, tutoring]
      : [childcare, seniorcare, adultcare, housekeeping, petcare, tutoring];
  }

  return prop?.isDaycareRemoved
    ? [childcare, seniorcare, housekeeping, petcare, tutoring]
    : [childcare, seniorcare, housekeeping, petcare, daycare, tutoring];
};
export default verticalsDisplayInfo;
