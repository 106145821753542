import { useState } from 'react';
import { Button, buttonClasses, Link, Menu, MenuItem, Typography } from '@mui/material';
import { Icon24UtilityChevronSmall } from '@care/react-icons';

import { LIST_YOUR_BUSINESS_LINKS } from '@/constants';
import { SxClassProps } from '@/types';

import { handleListYourBusinessClick } from './utils';

const classes: SxClassProps = {
  menuItem: (theme) => ({
    '& a': { color: theme.palette.care?.navy[900] },
    '&:hover a': {
      color: theme.palette.care?.navy[600],
    },
  }),
  trigger: (theme) => ({
    marginRight: theme.spacing(3),
    cursor: 'pointer',
    color: theme.palette.care?.navy[900],
    '& *': {
      color: `${theme.palette.care?.navy[900]} !important`,
      fill: `${theme.palette.care?.navy[900]} !important`,
    },
    '&:hover *': {
      color: `${theme.palette.care?.navy[600]} !important`,
      fill: `${theme.palette.care?.navy[600]} !important`,
    },
    [`& > .${buttonClasses.endIcon}`]: {
      mr: 0,
      ml: 0,
    },
  }),
};

export const ListYourBusinnessMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        sx={classes.trigger}
        endIcon={
          <Icon24UtilityChevronSmall
            size={24}
            sx={{
              mt: 0.4,
              transform: `rotate(${open ? 180 : 0}deg)`,
              transition: 'transform 300ms',
            }}
          />
        }
        onClick={handleClick}>
        <Typography variant="body1">List your business</Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        data-testid="list-you-business-menu"
        slotProps={{
          paper: {
            sx: ({ palette }) => ({
              border: `1px solid ${palette.care.grey[400]}`,
            }),
          },
        }}
        transformOrigin={{
          vertical: -16,
          horizontal: 0,
        }}
        open={open}
        onClose={handleClose}>
        {LIST_YOUR_BUSINESS_LINKS.map(({ label }) => (
          <MenuItem
            key={label}
            sx={classes.menuItem}
            onClick={(e) => handleListYourBusinessClick(e, 'VHP Header')}>
            <Link underline="none" variant="body2">
              {label}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};
