import React from 'react';
import { Icon24UtilityClose } from '@care/react-icons';
import { InternalLinkingMenu } from '@care/navigation';
import { Box } from '@mui/material';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { SxClassProps } from '@/types';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';
import { VERTICALS_NAMES } from '@/constants';
import useTestFlags from '@/components/hooks/useTestFlags';

interface HamburgerMenuProps {
  onClose?: Function;
  isMenuOpen?: Boolean;
}

const rootClass: SxClassProps = {
  root: (theme) => ({
    overflowY: 'auto',
    left: 0,
    top: 0,
    bottom: 0,
    width: '340px',
    padding: theme.spacing(6, 0),
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: theme.palette.care.white,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.15)',
    zIndex: theme.zIndex.drawer,

    [theme.breakpoints.up('lg')]: {
      position: 'absolute',
      padding: theme.spacing(3, 0),
      borderRadius: '0 0 16px 16px',
      top: '100%',
      left: '50%',
      bottom: 'auto',
      transform: `translate(calc(-100% + 42px), 0)`,
    },

    [theme.breakpoints.down('lg')]: {
      position: 'fixed',
    },
  }),
};

const getClasses = (isMenuOpen: false | Boolean): SxClassProps => ({
  root: rootClass,
  hamburger: [
    Array.isArray(rootClass.root) ? rootClass.root[0] : rootClass.root,
    isMenuOpen
      ? {
          transform: 'none',
          transition: 'transform .33s cubic-bezier(0,0,.3,1)',
        }
      : {
          transform: 'translateX(-101%)',
          willChange: 'transform',
          transition: 'transform .15s cubic-bezier(0,0,.3,1)',
        },
  ],
  hiddenMenu: {
    transform: 'translateX(-101%)',
    willChange: 'transform',
    transition: 'transform .15s cubic-bezier(0,0,.3,1)',
  },
  animatedHeaderMenu: {
    transform: 'none',
    transition: 'transform .33s cubic-bezier(0,0,.3,1)',
  },
  closeButton: (theme) => ({
    backgroundColor: theme.palette.care.white,
    display: 'none',
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(3),
    cursor: 'pointer',
    zIndex: theme.zIndex.tooltip,

    '& > svg': {
      height: theme.spacing(3),
      marginTop: 0,
      width: theme.spacing(3),
    },

    [theme.breakpoints.down('lg')]: {
      display: 'flex',
    },
  }),
});

const amplitudeData = {
  enrollment_initiated: false,
  isMenuOpen: true,
  screen_name: ScreenName.VISITOR_HOMEPAGE,
  vertical: VERTICALS_NAMES.unknown,
};

const getCtaText = (showPlansPricingCTA: boolean, eventData?: { label?: string }) => {
  let ctaText;
  if (eventData && eventData.label) {
    if (showPlansPricingCTA && eventData.label === 'SEO mega menu link click: plans and pricing') {
      ctaText = CtaText.PLANS_AND_PRICING;
    }

    const ListYourBusineesCTAs = [
      CtaText.LIST_YOUR_BUSINESS,
      CtaText.LIST_YOUR_DAYCARE_BUSINESS,
      CtaText.LIST_YOUR_CAMPS_BUSINESS,
      CtaText.LIST_YOUR_SENIORCARE_BUSINESS,
    ];
    const clickedCta = ListYourBusineesCTAs.find((ctaLabel) =>
      eventData.label?.toLowerCase().includes(ctaLabel.toLowerCase())
    );

    if (clickedCta) {
      ctaText = clickedCta;
    }
  }
  return ctaText;
};

const HamburgerMenu = (props: HamburgerMenuProps) => {
  const { onClose = () => {}, isMenuOpen = false } = props;
  const classes = getClasses(isMenuOpen);
  const { plansPricing } = useTestFlags();
  const showPlansPricingCTA = plansPricing.value === 2;

  const handleHamburgerButtonClick = (eventData?: { label?: string }) => {
    const extraEventData = () => {
      if (getCtaText(showPlansPricingCTA, eventData)) {
        return {
          cta_text: getCtaText(showPlansPricingCTA, eventData),
          ...amplitudeData,
        };
      }
      return undefined;
    };

    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        cta_clicked: CtaText.INTERNAL_LINKING_MENU,
        cta_location: CtaLocation.VHP_HEADER,
        ...eventData,
        ...extraEventData(),
      },
    });
  };

  return (
    <Box sx={classes.hamburger}>
      <Box
        data-testid="Header-menu-close-button"
        role="button"
        tabIndex={0}
        sx={classes.closeButton}
        onClick={() => onClose()}
        onKeyPress={() => onClose()}>
        <Icon24UtilityClose />
      </Box>

      <InternalLinkingMenu
        isContainingMenuOpen={isMenuOpen.valueOf()}
        logAnalyticsEvent={handleHamburgerButtonClick}
        renderForMW
        displayListYourBusiness
        displayPlansAndPricing={showPlansPricingCTA}
      />
    </Box>
  );
};

export default HamburgerMenu;
