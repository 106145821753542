import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import { IconLogoCare } from '@care/react-icons';
import TopNavigationDW from '@/components/shared/HomePageTopNavigation/TopNavigationDW';
import TopNavigationMW from '@/components/shared/HomePageTopNavigation/TopNavigationMW';
import SEOMegaNavDT from '@/components/shared/SEOMegaNavDT';
import SEOMegaNavMW from '@/components/shared/SEOMegaNavMW';
import useResponsive from '@/components/hooks/useResponsive';
import { LAYOUTS } from '@/constants';
import { Layout, SxClassProps } from '@/types';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { AnalyticsEventName, ScreenName } from '@/types/enums';

interface HeaderProps {
  layout: Layout;
  toggleModal?: () => void;
  showLogoLink?: boolean;
}

const getClasses = (layout: Layout): SxClassProps => ({
  root: [
    (theme) => ({
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor:
        layout === LAYOUTS.rebranding ? theme.palette.care?.white : theme.palette.care?.grey[50],
      position: 'relative',
      maxWidth: layout === LAYOUTS.rebranding ? 'none' : '1440px',
      width: '100%',
      padding: {
        xs: layout === LAYOUTS.rebranding ? theme.spacing(2, 1.5) : theme.spacing(1, 3),
        sm: theme.spacing(layout === LAYOUTS.rebranding ? 3 : 4, 4),
      },
    }),
  ],
});

const HeaderWithSEOMegaNav = (props: HeaderProps) => {
  const { toggleModal, layout, showLogoLink } = props;
  const { isDesktop } = useResponsive();
  const classes = getClasses(layout);
  const router = useRouter();

  const redirectToHome = () => {
    if (showLogoLink) {
      AnalyticsHelper.logEvent({
        name: AnalyticsEventName.CONSUMER_LOGO_CLICKED,
        data: {
          screen_name: ScreenName.PLANS_AND_PRICING,
          enrollment_initiated: false,
          cta_text: 'Care.com',
          cta_location: 'top_nav_bar',
          vertical: 'Unknown',
        },
      });
      router.push('/');
    }
  };

  return (
    <Box component="nav" sx={classes.root} data-testid="header">
      {isDesktop ? (
        <>
          <IconLogoCare
            height={32}
            width={202}
            data-testid="careLogo-desktop"
            onClick={redirectToHome}
            sx={{ ...(showLogoLink && { cursor: 'pointer' }) }}
          />
          <TopNavigationDW verticalsView toggleModal={toggleModal} />
          <SEOMegaNavDT />
        </>
      ) : (
        <>
          <SEOMegaNavMW />
          <IconLogoCare
            height={18}
            width={116.625}
            data-testid="careLogo-mobile"
            onClick={redirectToHome}
            sx={{ ...(showLogoLink && { cursor: 'pointer' }) }}
          />
          <TopNavigationMW verticalsView toggleModal={toggleModal} layout={layout} />
        </>
      )}
    </Box>
  );
};

export default HeaderWithSEOMegaNav;
