import React from 'react';
import { IconProps } from '@/types/icon';

function AdultCareIcon({ width, height }: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <circle fill="#e84e3a" cx="16.99" cy="10.67" r="5.55" />
      <path
        fill="#e84e3a"
        d="M16.99,16.72c-3.33,0-6.05-2.71-6.05-6.05s2.71-6.05,6.05-6.05,6.05,2.71,6.05,6.05-2.71,6.05-6.05,6.05ZM16.99,5.62c-2.78,0-5.05,2.26-5.05,5.05s2.26,5.05,5.05,5.05,5.05-2.26,5.05-5.05-2.26-5.05-5.05-5.05Z"
      />
      <circle fill="#f3dedc" cx="31.58" cy="10.67" r="5.55" />
      <path
        fill="#f3dedc"
        d="M31.58,16.72c-3.33,0-6.05-2.71-6.05-6.05s2.71-6.05,6.05-6.05,6.05,2.71,6.05,6.05-2.71,6.05-6.05,6.05ZM31.58,5.62c-2.78,0-5.05,2.26-5.05,5.05s2.26,5.05,5.05,5.05,5.05-2.26,5.05-5.05-2.26-5.05-5.05-5.05Z"
      />

      <path
        fill="#f3dedc"
        d="M23.72,42.88h13.08c.27,0,.48-.22.48-.48v-2.94s1.5.34,2.29-.47.49-2.57.49-5.52v-10.7h-16.7l.36,20.12Z"
      />
      <path
        fill="#f3dedc"
        d="M36.79,44.08h-14.25l-.4-22.52h19.12v11.9c0,.69.02,1.31.03,1.87.05,2.03.09,3.5-.86,4.49-.57.59-1.31.81-1.95.88v1.7c0,.93-.75,1.68-1.68,1.68ZM24.9,41.68h11.18v-3.73l1.47.34c.34.07.96.07,1.16-.14.25-.26.21-1.58.18-2.75-.01-.58-.03-1.22-.03-1.94v-9.5h-14.27l.31,17.72Z"
      />

      <path
        fill="#e84e3a"
        d="M31.11,25.26c2.75-.15,8.68.05,9.01-3.02.22-2.05-1.68-3.27-3.63-3.27H11.8c-4.22,0-3.92,4.36-3.92,4.36v13.97c0,2.06,2.82,1.89,2.82,1.89v3.29c0,.21.17.39.39.39h12.28v-17.64s5,.17,7.75.02Z"
      />
      <path
        fill="#e84e3a"
        d="M24.56,44.08h-13.48c-.88,0-1.59-.71-1.59-1.59v-2.19c-.63-.12-1.37-.37-1.94-.9-.57-.54-.87-1.26-.87-2.09v-13.97c-.01-.18-.12-2.47,1.4-4.1.91-.97,2.16-1.47,3.72-1.47h24.69c1.52,0,2.97.6,3.86,1.59.75.83,1.08,1.87.96,3.01-.4,3.75-5.85,3.94-9.1,4.05-.38.01-.73.03-1.04.04h0c-1.95.1-4.94.05-6.61.01v17.6ZM11.89,41.68h10.27v-17.68l1.24.04s4.98.16,7.64.02h0c.32-.02.69-.03,1.08-.04,2.15-.07,6.62-.23,6.8-1.9.05-.45-.07-.83-.36-1.15-.44-.49-1.24-.8-2.08-.8H11.8c-.88,0-1.53.23-1.97.7-.82.87-.75,2.36-.75,2.38v14.05c0,.21.06.29.12.34.28.27,1.02.37,1.43.35l1.26-.06v3.74Z"
      />
    </svg>
  );
}

AdultCareIcon.defaultProps = {
  width: '48',
  height: '48',
};

export default AdultCareIcon;
