export enum CtaLocation {
  VHP_MODAL = 'VHP Modal',
  VHP_HERO = 'VHP Hero',
  VHP_BODY = 'VHP Body',
  VHP_HEADER = 'VHP Header',
  TESTIMONIALS = 'Testimonials',
  HERO = 'Hero',
  FAQS = 'FAQs',
  SAFETY = 'Safety',
  HOW_IT_WORKS = 'How it works',
  Pricing = 'Pricing',
  MODAL = 'Modal',
}

export enum ScreenName {
  VISITOR_HOMEPAGE = 'Visitor Homepage',
  PLANS_AND_PRICING = 'Plans and pricing',
}

export enum CtaText {
  SEARCH = 'Search',
  GET_STARTED = 'Get started',
  TRUST_AND_SAFETY_CENTER = 'trust and safety center',
  JOIN_NOW = 'Join now',
  LOG_IN = 'Log in',
  APPLY_TO_JOBS = 'Apply to jobs',
  LIST_YOUR_BUSINESS = 'List your business',
  LIST_YOUR_DAYCARE_BUSINESS = 'List your early learning center',
  LIST_YOUR_CAMPS_BUSINESS = 'List your activity / camps center',
  LIST_YOUR_SENIORCARE_BUSINESS = 'List your senior living community',
  INTERNAL_LINKING_MENU = 'Internal linking menu',
  BECOME_A_CAREGIVER = 'Become a caregiver',
  EXPLORE_PLANS = 'Explore plans',
  LEARN_MORE = 'Learn more',
  PLANS_AND_PRICING = 'Plans and pricing',
}

export enum AnalyticsEventName {
  CTA_INTERACTED = 'CTA Interacted',
  CTA_INTERACTED_VHP = 'CTA Interacted - VHP',
  MEMBER_ENROLLED = 'Member Enrolled',
  TEST_EXPOSURE = 'Test Exposure',
  HOMEPAGE_VIEWED = 'Homepage Viewed',
  ITERABLE_ASSET_VIEWED = 'Iterable Asset Viewed',
  ITERABLE_ASSET_CLICKED = 'Iterable Asset Clicked',
  CONSUMER_LOGO_CLICKED = 'Consumer Logo Clicked',
}
