import { InternalLinkingMenu } from '@care/navigation';
import React from 'react';
import { Box } from '@mui/material';
import { AnalyticsHelper } from '@/utilities/AnalyticsHelper';
import { SxClassProps } from '@/types';

import { VERTICALS_NAMES } from '@/constants';
import { AnalyticsEventName, CtaLocation, CtaText, ScreenName } from '@/types/enums';

const classes: SxClassProps = {
  internalLinkingMenu: (theme) => ({
    margin: theme.spacing(-1, 0, -1, -0.25),
  }),
};

const SEOMegaNavDT = () => {
  const handleInternalLinkingMenuCLick = (eventData: Object) => {
    AnalyticsHelper.logEvent({
      name: AnalyticsEventName.CTA_INTERACTED_VHP,
      data: {
        screen_name: ScreenName.VISITOR_HOMEPAGE,
        enrollment_initiated: false,
        cta_text: CtaText.INTERNAL_LINKING_MENU,
        cta_location: CtaLocation.VHP_HEADER,
        vertical: VERTICALS_NAMES.unknown,
        ...eventData,
      },
    });
  };
  return (
    <Box sx={classes.internalLinkingMenu}>
      <InternalLinkingMenu logAnalyticsEvent={handleInternalLinkingMenuCLick} />
    </Box>
  );
};
export default SEOMegaNavDT;
